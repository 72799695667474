import React, { Component } from 'react'

class Footer extends Component {
    render() {
        return(
            <footer className="ck-footer">
                <div className="container ck-container-footer">
                    <div className="row">
                        <div className="ck-name-footer col-lg-8 col-md-7 col-sm-12">
                            <div className="ck-title-footer text-center text-md-left">
                                <a href="/" className="ck-name-footer-link">
                                    Amol Garhwal
                                </a>
                            </div>
                        </div>
                        <div className="ck-contacts-footer col-lg-4 col-md-5 col-sm-12">
                            <ul className="ck-contact-list">
                                <li>
                                    <a href="https://github.com/chillingking" target="_blank" rel="noreferrer">
                                        <ion-icon name="logo-github"></ion-icon>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://linkedin.com/in/amol-garhwal" target="_blank" rel="noreferrer">
                                        <ion-icon name="logo-linkedin"></ion-icon>
                                    </a>
                                </li>
                                <li>
                                    <a href="mailto:amol_11710007@nitkkr.ac.in" target="_blank" rel="noreferrer">
                                        <ion-icon name="mail-outline"></ion-icon>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="ck-body-footer text-center">Website developed by <a href="/" className="ck-name-footer-link">me</a>. Last updated on 28th November, 2020</div>
                </div>
            </footer>    
        )
    }
}

export default Footer