import React, { Component } from 'react'
import { Link } from 'gatsby'
import Resume from '../assets/AmolGarhwal_Resume.pdf'

class Header extends Component {
    render() {
        return(
            <header className="ck-header">
                <div className="container ck-container-header">
                    <nav className="navbar navbar-expand">
                        <div className="row">
                            <div className="col-lg-10">
                                <ul className="navbar-nav">
                                    <li className="nav-item">
                                        <Link className={`ck-header-link ${this.props.selected === 'home' ? 'ck-header-link-selected' : ''}`} to="/">Home <span className="sr-only">{`${this.props.selected === 'home' ? 'current' : ''}`}</span></Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className={`ck-header-link ${this.props.selected === 'about' ? 'ck-header-link-selected' : ''}`} to="/about">About <span className="sr-only">{`${this.props.selected === 'about' ? 'current' : ''}`}</span></Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className={`ck-header-link ${this.props.selected === 'work' ? 'ck-header-link-selected' : ''}`} to="/work">Work <span className="sr-only">{`${this.props.selected === 'work' ? 'current' : ''}`}</span></Link>
                                    </li>
                                </ul>
                            </div>
                            <a className="ck-resume_button" href={Resume} target="_blank" rel="noreferrer">
                                Résumé
                            </a>
                        </div>
                    </nav>
                </div>
            </header>    
        )
    }
}

export default Header